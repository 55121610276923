import React from 'react'
import Banner from './banner'
import { Box, Container } from '@material-ui/core'
import { Route } from 'react-router-dom'
import Bulletin from './bulletin'
import Bidding from './bidding'
import Partner from './partner'
import Ad from '../common/ad'
import ControlImg from '../common/control-img';
import { connect } from 'react-redux';
import {mapStateToProps,mapDispatchToProps} from '../../root.redux.js';
// import Ad1 from '../../assets/images/ad-1.png'

// 首页
const Home = props => {
  const {MIDDLE_AD} = props;
  return (
<Box
  marginTop={4}
  paddingBottom={5}
  className='body'
>
  {/* 轮播图 */}
  <Route component={Banner} />
  <Box
    component={Container}
    marginTop={5}
    marginBottom={-10}
  >
    <Ad />
  </Box>
  {/* 招标公告 */}
  <Route component={Bulletin} />
  <Box
    component={Container}
    marginBottom={-3}
    marginTop={-3}
  >
    <ControlImg style={{maxHeight:'60px',width:'100%'}} data={MIDDLE_AD} />
  </Box>
  {/* 中标公示 */}
  <Route component={Bidding} />
  {/* 合作伙伴 */}
  <Partner />
</Box>
  )
}


export default connect(mapStateToProps,mapDispatchToProps)(Home);