import React, { useEffect, useState } from 'react'
import { Box, Typography, Link } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import Request from '../../utils/request'
import moment from 'moment'

// 供手机端显示招标公告详情
const Mobile = props => {
  const { match } = props;
  const [detail, setDetail] = useState({});

  useEffect(() => {
    const { id, identity } = match.params;
    Request.get(`/supplier/tender/index/detail?pid=${id}`, { headers: { tenantIdentity: identity } })
      .then(data => {
        setDetail(data);
      })
      .catch(error => {
        console.warn(error);
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const classes = mobileStyles();

  const { projectname, orgname, estimatetotal, address, deadline, bondendtime, preopentime, bidfee, docnoticemod,docfilemod } = detail;
  const publish = docnoticemod && docnoticemod.publishTime;
  const dead = docnoticemod && docnoticemod.deadline;
  const contact = docnoticemod && docnoticemod.contact;
  const phone = docnoticemod && docnoticemod.phone;
  const publishTime = docfilemod && docfilemod.publishTime;

  return Object.getOwnPropertyNames(detail).length !== 0 &&
    ((docnoticemod && docnoticemod.content) ? <div dangerouslySetInnerHTML={{ __html: docnoticemod && docnoticemod.content }}></div> : 
    <Box
      className={classes.root}
    >
      <Typography
        variant='subtitle1'
        gutterBottom
        paragraph
      >
        四川华西集采电子商务有限公司是四川华西集团所属的大宗材料集中采购唯一实施单位，现就
        <Typography variant='inherit' component='u' >{orgname}</Typography>
        承建的
        <u>{projectname}</u>
        建筑钢材采购事宜进行公开招标，欢迎有意向的供应商参加投标。
      </Typography>
      <Title
        content='一、采购内容'
        classes={classes}
      />
      <Typography
        variant='body1'
        gutterBottom
        paragraph
      >
        项目建筑钢材（各种型号），预计总用量约为：<strong><u>{estimatetotal}</u></strong>吨。
      </Typography>
      <Title
        content='二、项目概况'
        classes={classes}
      />
      <Typography
        variant='body1'
        gutterBottom
        paragraph
      >
        项目名称：<strong><u>{projectname}</u></strong>
      </Typography>
      <Typography
        variant='body1'
        gutterBottom
        paragraph
      >
        项目地址：<strong><u>{address}</u></strong>
      </Typography>
      <Typography
        variant='body1'
        gutterBottom
        paragraph
      >
        其他详见招标文件。
      </Typography>
      <Title
        content='三、招标方式'
        classes={classes}
      />
      <Typography
        variant='body1'
        gutterBottom
        paragraph
      >
        本次采购招标采用公开招标的方式进行。
      </Typography>
      <Title
        content='四、投标人资格要求'
        classes={classes}
      />
      <Typography
        variant='body1'
        gutterBottom
        paragraph
      >
        在华西云采平台已注册的试用及合格的钢材分供商均可报名、投标。投标人请自行登录华西云采平台
              <Link href='http://www.hxyc.com.cn' target="_blank">www.hxyc.com.cn</Link>
        对本招标公告签收和报名。
      </Typography>
      <Title
        content='五、招投标时间节点'
        classes={classes}
      />
      <Typography
        variant='body1'
        gutterBottom
        paragraph
      >
        （一）招标文件发售时间：<strong><u>
          {docnoticemod && moment(publish).format('YYYY/MM/DD HH:mm:ss')}
        </u></strong>
        至
              <strong><u>
          {docnoticemod && moment(dead).format('YYYY/MM/DD HH:mm:ss')}
        </u></strong>。
      </Typography>
      <Typography
        variant='body1'
        gutterBottom
        paragraph
      >
        （二）投标保证金缴纳的截止时间为：
              <strong><u>
          {bondendtime && moment(bondendtime).format('YYYY年MM月DD日 HH:mm:ss')}
        </u></strong>
      </Typography>
      <Typography
        variant='body1'
        gutterBottom
        paragraph
      >
        （三）本次招标的投标截止时间为：
              <strong><u>
          {deadline && moment(deadline).format('YYYY年MM月DD日 HH:mm:ss')}
        </u></strong>。
      </Typography>
      <Typography
        variant='body1'
        gutterBottom
        paragraph
      >
        （四）开标时间为：
              <strong><u>
          {preopentime && moment(preopentime).format('YYYY年MM月DD日 HH:mm:ss')}
        </u></strong>。
      </Typography>
      <Typography
        variant='body1'
        gutterBottom
        paragraph
      >
        （五）招标文件售价：人民币 ￥{bidfee}元/套，售后不退。
      </Typography>
      <Title
        content='六、投标注意事项'
        classes={classes}
      />
      <Typography
        variant='body1'
        gutterBottom
        paragraph
      >
        投标单位缴纳标书费后，待招标人确认后请投标单位自行通过华西云采平台
              <Link href='http://www.hxyc.com.cn' target="_blank">www.hxyc.com.cn</Link>
        下载电子版招标文件（PDF文档格式）。
      </Typography>
      <Typography
        variant='body1'
        gutterBottom
        paragraph
      >
        按照招标文件的要求，投标人需在投标保证金截止时间之前通过银行转账的方式缴纳投标保证金后，方可在华西云采平台
              <Link href='http://www.hxyc.com.cn' target="_blank">www.hxyc.com.cn</Link>
        编制、提交投标文件、完成投标。本次招标过程中，招标人仅接受投标单位通过华西云采平台
              <Link href='http://www.hxyc.com.cn' target="_blank">www.hxyc.com.cn</Link>
        提交的投标文件，不接受通过其他方式提交的书面投标文件。线上投标内容具有法律效力且具有唯一性，请投标单位慎重对待。
      </Typography>
      <Typography
        variant='body1'
        gutterBottom
        paragraph
      >
        投标人将标书费、投标保证金转入招标人下列银行账户：在银行转账过程中，请注明
              <strong>“{orgname}{projectname}标书费、投标保证金”</strong>。
      </Typography>
      <Typography
        variant='body1'
        gutterBottom
        paragraph
      >
        打款账户请供应商中心查看
      </Typography>
      <Title
        content='七、其他事项'
        classes={classes}
      />
      <Typography
        variant='body1'
        gutterBottom
        paragraph
      >
        若对招标公告有其他不明事项，请致电
              <strong><u>{phone}</u></strong>
        ，联系人：
              <strong><u>{contact}</u></strong>
      </Typography>
      <Title
        content='八、声明'
        classes={classes}
      />
      <Typography
        variant='body1'
        gutterBottom
        paragraph
      >
        本招标公告仅为信息发布，不构成任何法律意义上的要约或承诺。
      </Typography>
      <Typography
        variant='body1'
        gutterBottom
        paragraph
      >
        现予公告
      </Typography>
      <Footer content='四川华西集采电子商务有限公司' />
      {/* <Footer content={publishTime && new Date(publishTime).toLocaleDateString('cn', { hourCycle: 'h24' })} /> */}
      <Footer content={publishTime && moment(publishTime).format('YYYY年MM月DD日 HH:mm')} />
    </Box >)
}

const Title = props =>
  <Typography
    variant='h6'
    component='h2'
    gutterBottom
    className={props.classes.title}
  >{props.content}</Typography>

const Footer = props =>
  <Typography
    variant='body1'
    align='right'
  >
    {props.content}
  </Typography>


const mobileStyles = makeStyles(theme => ({
  root: {
    fontSize: 16,
    textIndent: 20,
    padding: 10,
    lineHeight: 20,
    marginTop: 20,
    marginBottom: 20,
  },
  title: {
    fontWeight: 'bold',
    textIndent: 0,
  }
}))

export default Mobile;