// confirm.js
import React from 'react';
import ReactDOM from 'react-dom';
import ConfirmModal from './modal';

const confirm = ({ onSuccess }) => {
  const div = document.createElement('div');
  document.body.appendChild(div);

  function destroy() {
    const unmountResult = ReactDOM.unmountComponentAtNode(div);
    if (unmountResult && div.parentNode) {
      div.parentNode.removeChild(div);
    }
  }

  const handleSucess = (props) => {
    onSuccess && onSuccess({
      ...props,
      destroy
    })
  };


  ReactDOM.render(
    <ConfirmModal
      onSuccess={handleSucess}
    />,
    div
  );
};

export default confirm;