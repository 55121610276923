import { Modal, Spin } from 'antd';
import React, { useState, useEffect } from 'react';
import { Vertify } from '@alex_xu/react-slider-vertify';
function getRandomNumberByRange(start = 0, end = 100) {
  return Math.round(Math.random() * (end - start) + start);
}
const ConfirmModal = ({ onSuccess }) => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setVisible(true);
  }, []);

  const handleSucess = () => {
    onSuccess && onSuccess({
      triggerLoading: () => {
        setLoading(s => !s)
      }
    })
  }
  const getImage = ()=>{
    return `${window.location.origin}/shared-base-data/basic/v1/oss/picture/${getRandomNumberByRange(0,30)}}`
  }
  const [imgUrl,setImgUrl] = useState(getImage())
  return (
    <Modal
      visible={visible}
      closable={false}
      title='访问次数过多，请完成下列验证'
      width={380}
      footer={null}
    >
      <Spin spinning={loading}>
        <Vertify onBeforeRefresh={()=>{
          setImgUrl(getImage())
        }} onSuccess={handleSucess} imgUrl={imgUrl} text="按住左边按钮拖动完成上方拼图" />
      </Spin>
    </Modal>
  );
};

export default ConfirmModal;