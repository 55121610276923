import React from 'react'
import { Box, Grid } from '@material-ui/core'
import { connect } from 'react-redux';
import ControlImg from './control-img';
import {mapStateToProps,mapDispatchToProps} from '../../root.redux.js';

const Ad = props => {
  const {TOP_AD_1,TOP_AD_2,TOP_AD_3} = props;
  return <Box
    marginTop={2}
    marginBottom={5}
  >
    <Grid
      container
      spacing={1}
    >
      <Grid
        item
        xs={4}
      >
        <ControlImg style={{maxHeight:'80px',width:'100%'}} data={TOP_AD_1} />
      </Grid>
      <Grid
        item
        xs={4}
      >
        <ControlImg style={{maxHeight:'80px',width:'100%'}} data={TOP_AD_2} />
      </Grid>
      <Grid
        item
        xs={4}
      >
        <ControlImg style={{maxHeight:'80px',width:'100%'}} data={TOP_AD_3} />
      </Grid>
    </Grid>
  </Box>
}

export default connect(mapStateToProps,mapDispatchToProps)(Ad);