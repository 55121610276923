import Axios from "axios"
import { fetchLimiting } from "../components/slide-check/api";
import confirm from '../components/slide-check/confirm'
const Request = Axios.create({
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8'
  }
});

// Add a request interceptor
Request.interceptors.request.use(config => {
  // Do something before request is sent
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});
let isModalShow = false
// Add a response interceptor
Request.interceptors.response.use(response => {
  // Do something with response data
  if (response.status === 200 && `${response.data.status}` === '0') {
    return Promise.resolve(response.data.data);
  } else if (response.status === 200 && (Number(response.data.status) === 5)) {
    if (!isModalShow) {
      const [url] = (response.config.url || '').split('?')
      confirm({
        onSuccess({
          triggerLoading,
          destroy
        }) {
          triggerLoading()
          fetchLimiting({
            url: url.replace('/supplier/tender', '/dataService')
          }).then(() => {
            destroy()
            setTimeout(() => {
              window.location.reload()
            }, 1000);
            isModalShow = false
          }).finally(() => {
            triggerLoading()
          })
        }
      })
      isModalShow = true
    }
  } else {
    return Promise.reject(response.data.msg);
  }
}, error => {
  // Do something with response error
  return Promise.reject(error);
});


export default Request;