import React from 'react'
// import Ad from '../../assets/images/60ad.gif'
// import Logo1 from '../../assets/images/logo-1.png'
import Container from '@material-ui/core/Container'
import { Box, Grid } from '@material-ui/core'
import Alert from './alert'
import Title from './title'
import SearchBox from './searchBox'
// import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
  import ControlImg from './control-img';
import {mapStateToProps,mapDispatchToProps} from '../../root.redux.js';
// E:\git\supplier-portal\src\root.redux.js
// header
class Header extends React.Component {

  // 可搜索栏目
  types = [{
    index: 0,
    text: '招标公告',
    url: '/tender/notice',
  }, {
    index: 1,
    text: '中标公示',
    url: '/tender/result',
  }]

  constructor(props) {
    super(props);
    this.state = {
    }
  }

  // 搜索路由跳转
  search = (type, query) => {
    this.props.history.push(`${type.url}/1/${query}`);
  }

  render() {
    return <Box>
      {/* 上方黄条 */}
      {/* 神特么黄条 */}
      <Alert />
      {/* 标题栏 */}
      <Title />
      <Box component={Container} fixed paddingTop={5}>
        <Grid
          spacing={3}
          container
        >
          {/* logo图片 */}
          <Grid item xs={3}>
            {/* <Link to="/"> */}
              <ControlImg style={{height: 60,width: 'auto'}} data={this.props.TOP_LOGO} />
            {/* </Link> */}
          </Grid>
          <Grid item xs={3}>
            {/* <Link to="/"> */}
              <ControlImg style={{height: 60,width: 'auto'}} data={this.props.TOP_SIGN} />
            {/* </Link> */}
          </Grid>
          <Grid xs={6} item>
            {/* 搜索框 */}
            <SearchBox search={this.search} types={this.types} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(Header);