export const ES_API = '/supplier/tender'
// export const ES_API = '/dataService'
export const ES_LIST_API = `${ES_API}/search`
export const ES_DETAILS_API = `${ES_API}/details`

const url = {
  notice: ES_LIST_API + '/tenderDoc',
  noticeDetails: ES_DETAILS_API + '/tenderDoc',
  publicity: ES_LIST_API + '/publicity',
  publicityDetails: ES_DETAILS_API + '/publicity',
  noticeChange: ES_DETAILS_API + '/exchange',
  /** 终止公告 */
  result: ES_LIST_API + '/termination',
  /** 中标公告 */
  affiche: ES_LIST_API + '/result',
  /** 更改公告 */
  change: ES_LIST_API + '/change',
}

export default url;
