import React from 'react'
import Container from '@material-ui/core/Container'
import { Box } from '@material-ui/core'

export default function Alert() {
  return <Box
    textAlign="left"
    fontFamily='"Microsoft YaHei","Hiragino Sans GB","WenQuanYi Micro Hei", sans-serif'
    fontSize={13}
    lineHeight={2.5}
    style={{
      height: 35,
      backgroundColor: '#fbeeb1',
      borderBottom: '1px #e4c324 solid'
    }}
  >
    <Container fixed>
      <strong style={{ color: '#c7181e' }}>公告：</strong>
      全新升级改版，推荐使用谷歌浏览器或IE8以上浏览器或360浏览器极速模式，使用过程如有疑问请咨询热线：400-000-2772
    </Container>
  </Box>
}
