import { Decrypt } from "./crypto"


/** 
 * @param {{identity:string;url:string;}} obj
 */
export const getIdentityUrl = ({
  identity,
  url
}) => {
  let _url = identity ? `identity=${identity}` : ''
  let _urlPrefix = '?'
  if (url) {
    if (url.includes('?')) {
      _urlPrefix = url + '&'
    } else {
      _urlPrefix = url + '?'
    }
  }
  return _urlPrefix + _url
}
/** 
 * 历史代码原因
 * @param {{tabType:'招标公告'|'中标公示'}} props 
 */
export const JumpLink = ({
  tabType,
  id: oid,
  identity,
  type
}) => {
  const id = Decrypt(oid)
  switch (tabType) {
    // 招标公告
    case '招标公告':
      return `/bulletin/detail/${id}/${identity}/${type}`;
    // 中标公示
    case '中标公示':
      return `/bidding/detail/${id}/${identity}/${type}`;
    default:
      return null
  }
}
/** 
 * @param {string} key 
 * @returns {string}
 */
export function getQueryParam(key) {
  var queryString = window.location.search.substring(1);
  var queryParams = queryString.split('&');
  for (var i = 0; i < queryParams.length; i++) {
    var pair = queryParams[i].split('=');
    if (decodeURIComponent(pair[0]) === key) {
      return decodeURIComponent(pair[1]);
    }
  }
  return null;
}
