import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'

// 标题栏
const PlanTitle = props => {
  const { title, to, more } = props;
  return <Grid container spacing={0}>
    <Grid item xs={4}>
      <Typography
        variant='inherit'
        style={{
          fontWeight: '700',
          fontSize: 18,
          textIndent: 10,
          marginLeft: 10,
        }}
        gutterBottom
      >
        {title}
      </Typography>
    </Grid>
    <Grid item xs={6}>
    </Grid>
    {more && <Grid
      item
      xs={2}
      style={{
        textAlign: 'right',
        paddingTop: 5,
      }}
    >
      <Link to={to}>
        <Typography
          fontSize={12}
          variant='inherit'
          style={{
            color: '#666',
            fontSize: 12,
            marginRight: 10,
          }}
        >
          更多>
          </Typography>
      </Link>
    </Grid>}
  </Grid>
}

export default PlanTitle;