import { useState, useEffect } from 'react'
/** 
 * @param {object} params
 * @param {number} params.current
 * @param {number} params.total
 * @param {(page:number,pageSize,number)} params.onChange
 */
export const antdPaginationPropsToOld = (params) => {
  const { current, total, onChange } = params
  return {
    /** 别问为什么，兼容瓜皮代码 */
    page: current - 1,
    count: total,
    onChangePage: (_, page) => {
      onChange(page + 1)
    }
  }
}
/** 
 * @param {object} params
 * @param {number} params.current
 * @param {boolean} params.loading
 * @param {number} params.total
 * @param {Array<any>} params.dataSource
 * @param {(page:number,pageSize,number)} params.onChange
 */
export const antdTablePropsToOld = (params) => {
  const { dataSource, loading, ...rest } = params
  return {
    list: dataSource,
    loading: loading,
    paginationProps: antdPaginationPropsToOld(rest)
  }
}

/** 
 * @param {({current:number})=>Promise<{total:number;list:Array<any>}>} serve
 * @param {object} params
 * @param {Array<any} params.refreshDeps
 */
export const useTable = (serve, params) => {
  const { refreshDeps } = params
  const [current, setCurrent] = useState(1);
  const [total, setTotal] = useState(0);
  const [dataSource, setDataSource] = useState([])
  const [loading, setLoading] = useState(false)
  const getList = (params) => {
    setLoading(true)
    serve(params).then((data) => {
      setTotal(data.total)
      setDataSource(data.list || [])
    }).finally(() => {
      setLoading(false)
    })
  }
  useEffect(() => {
    setCurrent(1)
    getList({ current: 1 })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, refreshDeps);
  const onChange = (page) => {
    setCurrent(page)
    getList({ current: page })
  }
  const submit = (otherParams) => {
    setCurrent(1)
    getList({ current: 1, ...otherParams })
  }
  return {
    tableProps: antdTablePropsToOld({ current, total, dataSource, loading, onChange }),
    search: {
      submit
    }
  }
}