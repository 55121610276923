import React, { } from "react"
import { Container, Box, Paper, Typography, } from '@material-ui/core'
import Request from '../../utils/request'
import Breads from "./breads"
import './bulletin-detail.css'
import url from "../../utils/url"

// 招标公告详情
export default class BulletinDetail extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      id: props.match.params.id,
      identity: props.match.params.identity,
      type: props.match.params.type,
      detail: {},
    }
  }

  // 初始化
  componentDidMount() {
    const { id } = this.state;
    Request.get(`${url.noticeDetails}/${id}`)
      .then(data => {
        this.setState({
          detail: data,
        })
      })
      .catch(error => {
        console.warn(error);
      })
  }

  render() {
    const { detail, type } = this.state;
    return Object.getOwnPropertyNames(detail).length !== 0 &&
      <Box
        style={{
          backgroundColor: '#f5f5f5',
          paddingBottom: 80,
        }}
      >
        <Container>
          {/* 面包靴导航 */}
          <Breads detail={detail} type={type} />
          <Paper
            elevation={0}
            square={true}
            style={{
              padding: 24,
            }}
          >
            <Detail detail={detail} identity={this.state.identity} pid={this.state.id} />
          </Paper>
        </Container>
      </Box>
  }
}
// 详情
// const Detail = props => {
function Detail(props) {
  const { detail } = props;
  const { name, content, publishTime: publish } = detail;


  return (<Box>
    <Typography
      variant='h5'
      align='center'
      style={{
        marginBottom: 30,
      }}
    >
      {name}
    </Typography>
    <Typography
      variant='subtitle2'
      align='center'
    >
      {/* {`四川华西集采电子商务有限公司招标公告${code.split('-')[2]}年${code.split('-')[3]}号`} */}
    </Typography>
    <Box
      whiteSpace={10}
      textAlign='center'
      style={{
        color: '#999',
        fontSize: 10,
        wordSpacing: 3,
        borderBottom: 1,
        borderBottomColor: '#ccc',
        borderBottomStyle: 'dotted',
        paddingBottom: 18,
      }}
      marginTop={2}
    >
      <Typography
        variant='inherit'
        style={{
          marginLeft: 10,
          marginRight: 10,
        }}
      >
        发表于：{publish}
      </Typography>

    </Box>
    {/* 当满足条件时只渲染content */}
    <div dangerouslySetInnerHTML={{ __html: content }}></div>
  </Box>)
}