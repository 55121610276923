import React from 'react'
import { Breadcrumbs, Typography, Link, Box } from '@material-ui/core'
import { Link as L } from 'react-router-dom'

export default function Breads({ detail }) {

  const { smallTile, name, underLineType } = detail;

  let link = '/3';
  switch (underLineType) {
    case '1,2':
      link = '/1';
      break;
    case '3':
      link = '/3';
      break;
    case '4':
      link = '/4';
      break;
    default:
      link = '/1';
      break;
  }
  return <Box
    marginBottom={3}
    paddingTop={2}
  >
    <Breadcrumbs aria-label="breadcrumb">
      <Link
        color="inherit"
        component={L}
        to={'/tender/notice/1'}
        style={{
          fontSize: 12,
        }}
      >
        招标公告
      </Link>
      <Link
        color="inherit"
        component={L}
        to={`/tender/notice${link}`}
        style={{
          fontSize: 12,
        }}
      >
        {smallTile || ''}
      </Link>
      <Typography
        variant='inherit'
        color="textPrimary"
        style={{
          fontSize: 12,
        }}
      >
        {name || ''}
      </Typography>
    </Breadcrumbs>
  </Box>
}
