import React from 'react'
import { AppBar, Tabs, Tab, Box, Typography, Grid, ListItem, ListItemText, List } from '@material-ui/core'
import { Link } from 'react-router-dom'
import Request from "../../utils/request"
import moment from 'moment';
import Url from '../../utils/url'
import { JumpLink } from '../../utils/common';

// 招标公告列表
export default class BulletinBody extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      cgType: 1,
      post: [[], [], []],
      tabs: props.tabs,
    };
  }

  // 初始化请求数据
  componentDidMount() {
    this.requestList();
  }

  // 请求数据
  requestList = () => {
    const { cgType, post, value } = this.state;
    Request.get(`${Url.notice}?type=${cgType}`)
      .then(data => {
        post[value] = data.data;
        this.setState({
          post: post,
        });
      })
      .catch(error =>
        console.warn(error)
      )
  };

  // 鼠标移入事件
  handleMouseEnter = (event, item) => {
    const { value, cgType } = this.state;
    // 重复移入时不触发后台请求
    if (value === item.index && cgType === item.cgType) {
    } else {
      this.setState({
        value: item.index,
        cgType: item.cgType,
      }, () => {
        // 更新状态后在回调中请求后台数据
        this.requestList();
      });
    }
  };

  render() {
    const { value, post, tabs } = this.state;
    return <AppBar
      position='static'
      color='inherit'
      elevation={0}
    >
      {/* 标签页 */}
      {tabs && <Tabs
        value={value}
      >
        {tabs.map((item, index) =>
          <Tab
            key={index}
            label={<Box
              display='inline-block'
            >
              <img
                style={{
                  marginRight: 5,
                  height: 16,
                }}
                src={item.icon}
                alt={item.text}
              />
              <Typography
                variant='inherit'
              >
                {item.text}
              </Typography>
            </Box>}
            onMouseEnter={(event) => this.handleMouseEnter(event, item)}
          />
        )}
      </Tabs>}
      {/* 列表 */}
      {post.map((item, index) =>
        // 列表面板
        <TabPanel
          key={index}
          value={value}
          index={index}
          list={item}
        />
      )}
    </AppBar>
  }
}

// 详情
const ListItemLink = props => {
  const { post } = props;


  let publishTime = post && post.publishTime;
  return <ListItem
    component={Link}
    to={JumpLink({
      tabType: '招标公告',
      id: post.id,
      identity: post.identity,
      type: post.type,
    })}
    button
    dense={true}
  >
    <Grid
      container
      spacing={3}
    >
      <Grid style={{ overflow: 'hidden', paddingRight: 0 }}
        item
        xs={3}
      >
        <Typography>
          <Typography
            variant='button'
            noWrap={true}
            style={{
              backgroundColor: moment(new Date()).valueOf() < moment(post && post.deadline).valueOf() ? '#ff8a22' : '#d8d8d5',
              color: '#fff',
              display: 'inline-block',
              padding: 5,
              boxSizing: 'border-box',
              borderRadius: 3,
              fontSize: 12,
            }}
          >
            {moment(new Date()).valueOf() < moment(post && post.deadline).valueOf() ? '进行中' : '已截止'}
          </Typography>
          <Typography
            variant='button'
            noWrap={true}
            style={{
              backgroundColor: '#d8d8d5',
              display: 'inline-block',
              width: '100%',
              maxWidth: '46%',
              color: '#fff',
              padding: 5,
              boxSizing: 'border-box',
              borderRadius: 3,
              fontSize: 12,
              marginLeft: '6%',
            }}
          >
            {post.tenantName}
          </Typography>
        </Typography>
      </Grid>
      <Grid style={{ paddingLeft: 8 }}
        item
        xs={7}
      >
        <ListItemText
          primary={
            <Typography noWrap={true}>
              <Typography
                component='span'
                variant='body2'
              >
                {post.planName}
              </Typography>
            </Typography>
          }
        />
      </Grid>
      <Grid
        item
        xs={2}
      >
        <Typography
          variant='inherit'
          noWrap={true}
          style={{
            color: '#999',
            fontSize: 14,
          }}
        >
          {moment(publishTime).format('YYYY/MM/DD')}
        </Typography>
      </Grid>
    </Grid>
  </ListItem>;
};

// 构建列表方法
const buildList = posts => {

  // 列表分栏过滤器
  const filter = (array, bool) => [].concat(...array.filter((item, index) => (index % 2 === 0) === bool));

  // 拆分列表
  const arr = [
    filter(posts, true),
    filter(posts, false),
  ]
  return arr.map((item, i) =>
    <Grid
      key={i}
      item
      xs={12 / arr.length}
    >
      <List
        component='nav'
      >
        {/* 构建连接 */}
        {item.map((post, index) => <ListItemLink key={index} post={post} />)}
      </List>
    </Grid>
  );
}

// 列表面板
const TabPanel = props => {
  const { children, value, index, list, ...other } = props;

  return <Typography
    component="div"
    hidden={value !== index}
    {...other}
  >
    <Box
      minHeight={420}
    >
      <Grid
        container
      >
        {/* 构建列表 */}
        {buildList(list)}
      </Grid>
    </Box>
  </Typography>
}