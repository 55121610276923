import React from 'react'
import { Box, Container, Paper } from '@material-ui/core'
import PlanTitle from './plan-title'
import BulletinBody from './bulletin-body'
import Icon1 from '../../assets/images/z5.png'
import Icon2 from '../../assets/images/z6_1.png'
import Icon3 from '../../assets/images/z7.png'

// 招标公告
const Bulletin = props =>
  <Box
    paddingY={5}
    component={Container}
  >
    <Paper
      square={true}
      elevation={0}
      style={{
        paddingTop: 12,
        paddingBottom: 12,
      }}
    >
      {/* 招标公告标题 */}
      <PlanTitle
        title='招标公告'
        // 更多跳转路径
        to='/tender/notice/1'
        // 是否显示更多
        more={true}
      />
      {/* 招标公告列表 */}
      <BulletinBody tabs={tabs} />
    </Paper>
  </Box>

// 标签页
const tabs = [
  { index: 0, text: '物资招标', icon: Icon1, cgType: 1 },
  { index: 1, text: '劳务分包', icon: Icon2, cgType: 3 },
  { index: 2, text: '专业分包', icon: Icon3, cgType: 4 },
  { index: 3, text: '机械设备', icon: Icon3, cgType: 5 },
  { index: 4, text: '其它服务', icon: Icon3, cgType: 7 },
];

export default Bulletin;