import React, { useState, useEffect } from 'react'
import { Box, makeStyles } from '@material-ui/core'
import SwipeableViews from 'react-swipeable-views'
import Pagination from './Pagination';
import { autoPlay } from 'react-swipeable-views-utils'
import Axios from 'axios';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
const styles = makeStyles(theme => ({
  root: {
    position: 'relative',
  },
  slide: {
    padding: 15,
    minHeight: 100,
    color: '#fff',
  },
  bannerImg:{
    margin: 'auto',
    maxHeight:'500px',
    width: '100%',
  }
}))

// 轮播图
const Banner = props => {
  const classes = styles();
  //当前轮播图
  const [banners, setbanners] = useState([]);
  //当前选中的轮播图
  const [index, setIndex] = useState(0);

  useEffect(() => {
    Axios.get('/shared-base-data/basic/v1/support/carousel-image').then(res => {
      const {data} = res;
      const result = data.map(el => {
        return {
          src:el.portalUrl,
          url:el.externalLinkUrl,
          jump:el.jump,
        }
      })
      setbanners(result);
    })
  }, []);

  const handleChangeIndex = index => {
    setIndex(index);
  }

  // 轮播图改为从后台接口获取

  return <Box className={classes.root}>
    <AutoPlaySwipeableViews index={index} onChangeIndex={handleChangeIndex}>
      {banners.map((banner, index) =>
        <Box key={index}>
          {(banner.jump && banner.url) ? (
          <a target="_blank" rel="noopener noreferrer" href={banner.url}>
            <img
              className={classes.bannerImg}
              src={banner.src}
              alt=''
            />
          </a>
          ):(
            <img
              className={classes.bannerImg}  
              src={banner.src}
              alt=''
            />
          )}
        </Box>)}
    </AutoPlaySwipeableViews>
    <Pagination dots={banners.length} index={index} onChangeIndex={handleChangeIndex} />
  </Box>
}

export default Banner;