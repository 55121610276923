import React,{useState} from "react"
import { Container, Box, Paper, Typography, Link, Button } from '@material-ui/core'
import Request from '../../utils/request'
import Breads from "./breads"
import axios from "axios"
import './bulletin-detail.css'
import moment from 'moment'
import Snackbar from "../Snackbar/Snackbar.jsx";

// 招标公告详情
export default class BulletinDetail extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      id: props.match.params.id,
      identity: props.match.params.identity,
      detail: {},
    }
  }

  // 初始化
  componentDidMount() {
    const { id, identity } = this.state;
    Request.get(`/supplier/tender/index/detail?pid=${id}`, { headers: { tenantIdentity: identity } })
      .then(data => {
        this.setState({
          detail: data,
        })
      })
      .catch(error => {
        console.warn(error);
      })
  }

  render() {
    const { detail } = this.state;
    return Object.getOwnPropertyNames(detail).length !== 0 &&
      <Box
        style={{
          backgroundColor: '#f5f5f5',
          paddingBottom: 80,
        }}
      >
        <Container>
          {/* 面包靴导航 */}
          <Breads detail={detail} />
          <Paper
            elevation={0}
            square={true}
            style={{
              padding: 24,
            }}
          >
            <Detail detail={detail} identity={this.state.identity} pid={this.state.id}/>
          </Paper>
        </Container>
      </Box>
  }
}
// 详情
// const Detail = props => {
function Detail (props) {
  const { detail,identity,pid } = props;
  const { 
    name, projectname, orgname, estimatetotal, address, 
    bondendtime, preopentime, bidfee, docnoticemod, docfilemod,bidStatus 
  } = detail;
  const publish = docnoticemod && docnoticemod.publishTime;
  const contact = docnoticemod && docnoticemod.contact;
  const phone = docnoticemod && docnoticemod.phone;
  const publishTime = docfilemod && docfilemod.publishTime;
  const deadline = docfilemod && docfilemod.deadline;
  // const bidStatus = bidStatus;
  const attachments = docnoticemod && docnoticemod.attachment;
  const [color, setcolor] = useState('warning');
  const [message, setmessage] = useState('');
  const [snackbarIsOpen, setsnackbarIsOpen] = useState(false);
  
  const closeSnackbar = () => {
    setsnackbarIsOpen(false);
  }
  
  const openSnackbar = () => {
    setsnackbarIsOpen(true);
    setTimeout(() => {
      setsnackbarIsOpen(false);
    }, 1600);
  }

  const signUp = params => {
    axios.get(`/supplier/tender/signUp?pid=${pid}`, { headers: { tenantIdentity: identity } })
      .then(res => {
        const {data} = res;//先从请求中取出data
        if (data.status === 0 || data.status === '0') {//这就妥了
          //请求成功说明您已经登录了
          const {type,signAnswer} = data.data;
          //type 0:无资格报名,1:已经报名,2:报名成功
          setmessage(signAnswer);//提示信息
          if (type === 2) {//弹窗的颜色
            setcolor('success');
          }else{
            setcolor('warning');
          }
          openSnackbar();          
        } else {//请求失败
          setmessage(data.msg ? data.msg : '出错了');
          setcolor('danger');
          openSnackbar();          
        }

      },err => {
        let response = (err && err.response) ? err.response : {};
        if (response.status === 401 || response.status === 403 || response.status === 405) {
          //未登录，弹出新标签页，并跳转登录页面
          const link = document.createElement('a');
          link.target = '_blank';
          link.href = window.origin + '/supplier';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }else{
          setmessage(response.msg ? response.msg : '出错了');
          setcolor('danger');
          openSnackbar();
        }
      })
  }
  return (<Box>
    <Typography
      variant='h5'
      align='center'
      style={{
        marginBottom: 30,
      }}
    >
      {name}
    </Typography>
    <Typography
      variant='subtitle2'
      align='center'
    >
      {/* {`四川华西集采电子商务有限公司招标公告${code.split('-')[2]}年${code.split('-')[3]}号`} */}
    </Typography>
    <Box
      whiteSpace={10}
      textAlign='center'
      style={{
        color: '#999',
        fontSize: 10,
        wordSpacing: 3,
        borderBottom: 1,
        borderBottomColor: '#ccc',
        borderBottomStyle: 'dotted',
        paddingBottom: 18,
      }}
      marginTop={2}
    >
      <Typography
        variant='inherit'
        style={{
          marginLeft: 10,
          marginRight: 10,
        }}
      >
        发表于：{publish}
      </Typography>
      {/* <Typography
        variant='inherit'
        style={{
          marginLeft: 10,
          marginRight: 10,
        }}
      >
        来源：华西云采网
      </Typography> */}
    </Box>
    {/* 当满足条件时只渲染content */}
    {(docnoticemod && docnoticemod.content) ? <div dangerouslySetInnerHTML={{ __html: docnoticemod && docnoticemod.content }}></div> :
      <Box>
        <Box
          marginTop={5}
        >
          <Typography
            variant='body1'
            style={{
              textIndent: 20,
            }}
          >
            四川华西集采电子商务有限公司是四川华西集团所属的大宗材料集中采购唯一实施单位，现就
            <Typography variant='inherit' component='u' >{orgname}</Typography>
            承建的
            <u>{projectname}</u>
            建筑钢材采购事宜进行公开招标，欢迎有意向的供应商参加投标。
          </Typography>
        </Box>
        <Typography
          variant='inherit'
          component='p'
          className='margin bold'
        >
          一、采购内容
          </Typography>
        <Typography
          variant='inherit'
          component='p'
          className='margin indent'
        >
          项目建筑钢材（各种型号），预计总用量约为：<strong><u>{estimatetotal}</u></strong>吨。
          </Typography>
        <Typography
          variant='inherit'
          component='p'
          className='margin bold'
        >
          二、项目概况
          </Typography>
        <Typography
          variant='inherit'
          component='p'
          className='margin indent'
        >
          项目名称：<strong><u>{projectname}</u></strong>
        </Typography>
        <Typography
          variant='inherit'
          component='p'
          className='margin indent'
        >
          项目地址：<strong><u>{address}</u></strong>
        </Typography>
        <Typography
          variant='inherit'
          component='p'
          className='margin indent'
        >
          其他详见招标文件。
          </Typography>
        <Typography
          variant='inherit'
          component='p'
          className='margin bold'
        >
          三、招标方式
          </Typography>
        <Typography
          variant='inherit'
          component='p'
          className='margin indent'
        >
          本次采购招标采用公开招标的方式进行。
          </Typography>
        <Typography
          variant='inherit'
          component='p'
          className='margin bold'
        >
          四、投标人资格要求
          </Typography>
        <Typography
          variant='inherit'
          component='p'
          className='margin indent'
        >
          在华西云采平台已注册的试用及合格的钢材分供商均可报名、投标。投标人请自行登录华西云采平台
          <Link href='http://www.hxyc.com.cn' target="_blank">www.hxyc.com.cn</Link>
          对本招标公告签收和报名。
          </Typography>
        <Typography
          variant='inherit'
          component='p'
          className='margin bold'
        >
          五、招投标时间节点
          </Typography>
        <Typography
          variant='inherit'
          component='p'
          className='margin indent'
        >
          （一）招标文件发售时间：<strong><u>
            {publishTime}
          </u></strong>
          至
            <strong><u>
            {deadline}
          </u></strong>。
          </Typography>
        <Typography
          variant='inherit'
          component='p'
          className='margin indent'
        >
          （二）投标保证金缴纳的截止时间为：
              <strong><u>
            {bondendtime}
          </u></strong>
        </Typography>
        <Typography
          variant='inherit'
          component='p'
          className='margin indent'
        >
          （三）本次招标的投标截止时间为：
          <strong><u>
            {deadline}
          </u></strong>。
          </Typography>
        <Typography
          variant='inherit'
          component='p'
          className='margin indent'
        >
          （四）开标时间为：
          <strong><u>
            {preopentime}
          </u></strong>。
          </Typography>
        <Typography
          variant='inherit'
          component='p'
          className='margin indent'
        >
          （五）招标文件售价：人民币 ￥{bidfee}元/套，售后不退。
          </Typography>
        <Typography
          variant='inherit'
          component='p'
          className='margin bold'
        >
          六、投标注意事项
          </Typography>
        <Typography
          variant='inherit'
          component='p'
          className='margin indent'
        >
          投标单位缴纳标书费后，待招标人确认后请投标单位自行通过华西云采平台
          <Link href='http://www.hxyc.com.cn' target="_blank">www.hxyc.com.cn</Link>
          下载电子版招标文件（PDF文档格式）。
          </Typography>
        <Typography
          variant='inherit'
          component='p'
          className='margin indent'
        >
          按照招标文件的要求，投标人需在投标保证金截止时间之前通过银行转账的方式缴纳投标保证金后，方可在华西云采平台
          <Link href='http://www.hxyc.com.cn' target="_blank">www.hxyc.com.cn</Link>
          编制、提交投标文件、完成投标。本次招标过程中，招标人仅接受投标单位通过华西云采平台
          <Link href='http://www.hxyc.com.cn' target="_blank">www.hxyc.com.cn</Link>
          提交的投标文件，不接受通过其他方式提交的书面投标文件。线上投标内容具有法律效力且具有唯一性，请投标单位慎重对待。
        </Typography>
        <Typography
          variant='inherit'
          component='p'
          className='margin indent'
        >
          投标人将标书费、投标保证金转入招标人下列银行账户：在银行转账过程中，请注明
          <strong>“{orgname}{projectname}标书费、投标保证金”</strong>。
        </Typography>
        <Typography
          variant='inherit'
          component='p'
          className='margin indent'
        >
          打款账户请供应商中心查看
        </Typography>
        <Typography
          variant='inherit'
          component='p'
          className='margin bold'
        >
          七、其他事项
        </Typography>
        <Typography
          variant='inherit'
          component='p'
          className='margin indent'
        >
          若对招标公告有其他不明事项，请致电
          <strong><u>{phone}</u></strong>
          ，联系人：
          <strong><u>{contact}</u></strong>
        </Typography>
        <Typography
          variant='inherit'
          component='p'
          className='margin bold'
        >
          八、声明
        </Typography>
        <Typography
          variant='inherit'
          component='p'
          className='margin indent'
        >
          本招标公告仅为信息发布，不构成任何法律意义上的要约或承诺。
        </Typography>
        <Typography
          variant='inherit'
          component='p'
          className='margin indent'
        >
          现予公告
        </Typography>
        <Typography
          component='p'
          align='right'
          className="margin"
        >
          四川华西集采电子商务有限公司
        </Typography>
        <Typography
          component='p'
          align='right'
          className="margin"
        >
          {moment(publishTime).format('YYYY/MM/DD HH:mm:ss')}
        </Typography>
        {attachments && attachments.split(',').map((attachment, index) =>
          <Button
            key={index}
            component='a'
            variant='contained'
            color='primary'
            href={`http://v2cs-oss.oss-cn-beijing.aliyuncs.com/${attachment}`}
            target='_blank'
            rel='noreferrer'
            style={{
              marginTop: 20,
              marginRight: 20,
            }}
          >
            查看附件{index + 1}
          </Button>
        )}
      </Box>
    }
    <Box textAlign='right'>
      {/* 我要参与投标按钮 */}
      {bidStatus === 1 ? <Button
        variant='contained'
        color='secondary'
        onClick={signUp}
        target='_blank'
        rel='noreferrer'
        style={{marginTop: 20,}}>
        我要参与投标
      </Button> : ''}
      <Snackbar open={snackbarIsOpen} place="bl" close nowrapMessage={true}
        closeNotification={closeSnackbar}
        message={message} color={color}/>
    </Box>
  </Box>)
}
