import React from 'react'
import { Breadcrumbs, Typography, Link, Box } from '@material-ui/core'
import { Link as L } from 'react-router-dom'
import { tenderAnnouncementTypesMap } from '../../utils/constants';
export default function Breads({ detail, type }) {

  const { name } = detail;
  const _type = `${type}` === '2' ? 1 : type
  return <Box
    marginBottom={3}
    paddingTop={2}
  >
    <Breadcrumbs aria-label="breadcrumb">
      <Link
        color="inherit"
        component={L}
        to={'/tender/notice/1'}
        style={{
          fontSize: 12,
        }}
      >
        招标公告
      </Link>
      <Link
        color="inherit"
        component={L}
        to={`/tender/notice/${_type}`}
        style={{
          fontSize: 12,
        }}
      >
        {tenderAnnouncementTypesMap[_type] + '招标公告' || ''}
      </Link>

      <Typography
        variant='inherit'
        color="textPrimary"
        style={{
          fontSize: 12,
        }}
      >
        {name || ''}
      </Typography>
    </Breadcrumbs>
  </Box>
}