import Axios from "axios"
import { Encrypt } from "../../utils/crypto"

export const fetchLimiting = ({ url }) => {
  return new Promise((resolve, reject) => {
    Axios.post(`/supplier/tender/limiting`, {
      url: url
    }).then(({ data: { data } }) => {
      Axios.post(`/supplier/tender/unlimiting`, {
        url: url,
        encrypt: Encrypt(data)
      }).then(() => {
        resolve()
      }).catch(() => {
        reject()
      })
    }).catch(() => {
      reject()
    })
  })
}