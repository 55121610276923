import React from 'react'
import { Link } from 'react-router-dom'
import { Popover,Box, Link as L, Container } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import './title.css'
import AppleDownload from '../../assets/images/jzy-apple-download.png'
import AndroidDownload from '../../assets/images/yaan_android_download.png'
const useStyles = makeStyles(theme => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
  popoverContent:{
    '& span':{
      display:'inline-block',
      textAlign:'center',
      padding:'10px'
    }
  }
}));

// class Title extends React.Component {
const Title = props => {
  const classes = useStyles();
  const login = false
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handlePopoverClose = () => {
    setAnchorEl(null);
  }

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const open = Boolean(anchorEl);

  // render() {
    // const name = "四川建造云"
    return <Box color={'#666'} height={30} fontSize={12} lineHeight={2.5} style={{ backgroundColor: '#f5f5f5' }}>
      <Container className="top-nav" fixed>
        <span style={{ marginRight: 25 }}>欢迎来到四川建造云</span>
        <span style={{ color: '#eb434c', marginRight: 25 }}>"建造云用户"</span>
        {!login && <span style={{ marginRight: 15 }}><L href={`${window.location.origin}/supplier`} target='_blank' rel='noreferrer' className='link'>请登录</L></span>}
        {!login && <span style={{ marginLeft: 5 }}><L href={`${window.location.origin}/reg`} target='_blank' rel='noreferrer' className='link'>免费注册</L></span>}
        <span style={{ paddingLeft: 25, color: '#eb434c' }}>推荐使用谷歌浏览器或IE8以上浏览器或360浏览器极速模式</span>
        <span className='l' aria-owns={open ? 'app-download-popover' : undefined} aria-haspopup="true"
          onMouseLeave={handlePopoverClose} onMouseEnter={handlePopoverOpen}>APP下载</span>
        <Popover id="app-download-popover" 
          className={classes.popover}
          classes={{
            paper: classes.paper,
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus>
          <div className={classes.popoverContent}>
            <span>
              <div><img width="156" alt='...' src={AppleDownload} /></div>
              <div>苹果APP下载</div>
            </span>
            <span>
              <div><img width="156" alt='...' src={AndroidDownload} /></div>
              <div>安卓APP下载</div>
            </span>
          </div>
        </Popover>
        <span className='l'>网站导航</span>
        <span className='l'><L href={"http://help.scjzy.net/"} className='link' target={'_blank'}>帮助中心</L></span>
        <span className='l red'>400-000-2772</span>
        <span className='l'><Link to={'/about'} className='link'>关于我们</Link></span>
        <span className='l'><Link to={''} className='link'>微信公众号</Link></span>
      </Container>
    </Box>
  // }
}

export default Title;