import { createStore } from 'redux'

const initialState = {
    //全部的图片变量
    TOP_LOGO:{
        url:'',
        externalLinkUrl:null
    },
    TOP_SIGN:{
        url:'',
        externalLinkUrl:null
    },
    TOP_AD_1:{
        url:'',
        externalLinkUrl:null
    },
    TOP_AD_2:{
        url:'',
        externalLinkUrl:null
    },
    TOP_AD_3:{
        url:'',
        externalLinkUrl:null
    },
    MIDDLE_AD:{
        url:'',
        externalLinkUrl:null
    },
    BOTTOM_LOGO:{
        url:'',
        externalLinkUrl:null
    },
}

function urlReducer(state = initialState, action) {
    switch (action.type) {
      case 'updateUrl':
        return {
            ...state,
            ...action.payload
        }
      default:
        return state
    }
}

const store = createStore(urlReducer);

export const mapDispatchToProps = dispatch => {
    return {
        updateUrl:(obj) => dispatch({
            type:'updateUrl',
            payload:obj
        }),
    }
};
export const mapStateToProps = (state) => ({
    TOP_LOGO:state.TOP_LOGO,
    TOP_SIGN:state.TOP_SIGN,
    TOP_AD_1:state.TOP_AD_1,
    TOP_AD_2:state.TOP_AD_2,
    TOP_AD_3:state.TOP_AD_3,
    MIDDLE_AD:state.MIDDLE_AD,
    BOTTOM_LOGO:state.BOTTOM_LOGO,
});

export default store;