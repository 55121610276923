import React from 'react'
import { Box, Button, Container } from '@material-ui/core'
import './nav.css'

// 导航条
const Nav = props => {

  // 导航栏目选中事件
  const toggleDrawer = item => event => {
    if (/(http|https):\/\/([\w.]+\/?)\S*/.test(item.url)) {
      window.open(item.url);
    } else {
      props.history.push(item.url);
    }
  }

  return <Box
    minHeight={40}
    style={{ color: '#fff', backgroundColor: '#EC4B43' }}
    marginTop={3}
  >
    <Box component={Container} textAlign='center' letterSpacing={3}>
      {buttons.map((item, index) => {
        return <Button
          key={index}
          className='nav'
          color='inherit'
          onClick={toggleDrawer(item)}
        >
          {item.text}
        </Button>
      })}
    </Box>
  </Box>
}

// 导航条栏目
const buttons = [
  { text: '首页', url: '/' },
  { text: '招标公告', url: '/tender/notice/1' },
  { text: '中标公示', url: '/tender/result/1' },
  // { text: '云采商城', url: 'http://mall.hxyc.com.cn' },
  { text: '云采指数', url: '' },
  { text: '云采金税', url: '' },
  // { text: '云采学院', url: 'http://college.hxyc.com.cn' },
  { text: '云采学院', url: 'http://help.scjzy.net' },
  { text: '云采助手', url: '' },
  // { text: '供应商入驻', url: '' },
  { text: '供应商入驻', url: `http://${window.location.host}/reg` },
  // { text: '常见问题', url: 'https://mp.weixin.qq.com/mp/homepage?__biz=MzI3NjUxNTczMg==&hid=2&sn=2aeeb75aad54a0e5c23983f7e7ba5a10#wechat_redirect' },
  { text: '常见问题', url: 'http://help.scjzy.net' },
  { text: '善建云学', url: 'http://sjyx.scjzy.net' },
]

export default Nav;