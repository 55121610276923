import React from 'react'
import { Box, Divider, Grid, ListItem, ListItemText, List, ListItemSecondaryAction, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'
import Request from "../../utils/request"
import Url from '../../utils/url'
import './bidding-body.css'
import { JumpLink } from '../../utils/common'

// 中标公示列表
export default class BiddingBody extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      post: [],
    };
  }

  // 初始化请求
  componentDidMount() {
    this.requestList();
  }

  // 请求后台
  requestList = () => {
    Request.get(`${Url.publicity}?rows=12`)
      .then(data => {
        const result = data.data;
        this.setState({
          post: result,
        });
      })
      .catch(error =>
        console.warn(error)
      )
  };


  render() {
    const { post } = this.state;
    return <Grid
      container
      style={{
        minHeight: 435,
      }}
    >
      {/* 构建列表 */}
      {buildList(post)}
    </Grid>
  }
}

// 构建列表
const buildList = array => {

  // 列表过滤器
  const filter = (array, bool) => [].concat(...array.filter((item, index) => (index % 2 === 0) === bool));

  // 拆分列表
  const arr = [
    filter(array, true),
    filter(array, false),
  ]

  return arr.map((list, key) =>
    <Grid
      item
      key={key}
      xs={12 / arr.length}
    >
      <List
        component='nav'
      >
        {list && list.map((item, index) =>
          <Box
            key={index}
          >
            {/* 详情 */}
            <ListItemLink
              item={item}
              to={JumpLink({
                tabType: '中标公示',
                id: item.id,
                identity: item.identity,
                type: item.type,
              })}
            // 信息来源判断
            />
            {/* 分隔线 */}
            <Divider
              variant='middle'
            />
          </Box>
        )}
      </List>
    </Grid>
  )
}

// 详情
const ListItemLink = props => {
  const { item, ...other } = props;



  return <ListItem
    button
    component={Link}
    {...other}
  >
    <Typography
      noWrap={true}
      component={ListItemText}
      primary={<Typography
        variant='inherit'
      >
        {item.planName}
      </Typography>}
      secondary={item.publishTime}
    />
    <ListItemSecondaryAction
      style={{
        color: '#999',
        fontSize: 12,
        marginTop: 10,
      }}
    >
      {/* <p>{item.viewCount || 0}人关注</p> */}
    </ListItemSecondaryAction>
  </ListItem>
}