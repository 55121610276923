const secretKey = '1221567981QWEASD'
/** 加密方法 */
export function Encrypt(text) {
  const key = window.CryptoJS.enc.Utf8.parse(secretKey);
  const srcs = window.CryptoJS.enc.Utf8.parse(text);
  const encrypted = window.CryptoJS.AES.encrypt(srcs, key, { mode: window.CryptoJS.mode.ECB, padding: window.CryptoJS.pad.Pkcs7 });
  return encrypted.toString();
}
/** 解密方法 */
export function Decrypt(text) {
  var key = window.CryptoJS.enc.Utf8.parse(secretKey);
  var decrypt = window.CryptoJS.AES.decrypt(text, key, { mode: window.CryptoJS.mode.ECB, padding: window.CryptoJS.pad.Pkcs7 });
  const str = window.CryptoJS.enc.Utf8.stringify(decrypt).toString();
  return str
}
