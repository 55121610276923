import React from 'react'
import { Typography, Box, makeStyles, List, ListItem, ListItemAvatar, ListItemText, Divider, Paper } from '@material-ui/core'
import './ad-list.css'
import Icon7 from '../../assets/images/n_icon7.png'

const styles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
    fontSize: 12,
    marginTop: 5,
  },
}));

// 列表数据
const listData = [
  {
    title: '为什么要入驻华西云彩？',
    secondary: '建筑行业不好做，入驻华西云采丰富的项目资源可选。',
  },
  {
    title: '不知道如何入驻供应商？',
    secondary: '简单快速注册，在线提交资料，在线审批，也可联系客服，全程协助。',
  },
  {
    title: '投标、履约、验收、结算等环节复杂吗？',
    secondary: 'PC+移动端均可在线完成从招标寻源到合同履约，再到支付结算环节，减少线下环节，全程线上化处理，高效及时。',
  },
  {
    title: '担心供货完成却收不到货款？',
    secondary: '先投标，中标后开始履约供货，多种金融模式保障供应商家货款及时到位。',
  },
  {
    title: '对供应商家评价体系如何考量？',
    secondary: '全程线上评价，验收完成及开始评价，评价结果直接纳入供应商考评。',
  },
]

// 为什么选择华西云彩广告条
const AdList = props => {
  const classes = styles();

  return <Paper
    square={true}
    elevation={0}
  >
    <img
      style={{
        width: '100%',
      }}
      src={Icon7} alt=''
    />
    <List className={classes.root}>
      {listData.map((item, index) =>
        <Box
          key={index}
        >
          <ListItem
            alignItems='flex-start'
          >
            <ListItemAvatar>
              <Box
                width={30}
                height={28}
                className={`icon-${index}`}
              >
              </Box>
            </ListItemAvatar>
            <ListItemText
              primary={item.title}
              secondary={
                <React.Fragment>
                  <Typography
                    component='span'
                    variant='body2'
                    className={classes.inline}
                    color='textSecondary'
                  >
                    {item.secondary}
                  </Typography>
                </React.Fragment>
              }
            />
          </ListItem>
          <Divider variant='inset' component='li' />
        </Box>
      )}
    </List>
  </Paper>
}

export default AdList;