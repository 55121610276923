import React, { useState } from 'react'
import { Box, Container, TableCell, Tabs, Tab, Table, TableHead, TableRow, Paper, TableBody, TableFooter, TablePagination, IconButton, ListItem, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'
import Ad from '../common/ad'
import Request from "../../utils/request"
import Url from '../../utils/url'
import { makeStyles } from '@material-ui/styles'
import LastPageIcon from '@material-ui/icons/LastPage'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import moment from 'moment'
import { useTable } from '../../utils/table-util'
import { JumpLink } from '../../utils/common'
// 招标公告列表页
const TenderNotice = props => {
  const { match } = props;
  // 初始选中的tab页
  let initTabIndex = 0;
  // 根据url参数设置选中标签页
  switch (parseInt(match.params.t, 10)) {
    case 1:
      initTabIndex = 0;
      break;
    case 3:
      initTabIndex = 1;
      break;
    case 4:
      initTabIndex = 2;
      break;
    case 5:
      initTabIndex = 3;
      break;
    case 7:
      initTabIndex = 4;
      break;
    default:
      initTabIndex = 0;
      break;
  }
  // 选中标签页值
  const [value, setValue] = useState(initTabIndex);
  // 选中标签页
  const [tab, setTab] = useState(tabs[initTabIndex]);
  // 数据列表
  const handleChange = (event, value) => {
    setValue(value);
    setTab(tabs[value]);
  }
//------------------ 请求 start ------------------
const serveApi = (params) => {
  return new Promise((resolve,) => {
    Request.get((Url.notice), {
      params: {
        'page': params.current,
        'rows': 10,
        'type': tab.t,
        'keyword': match.params.k || '',
      }
    })
      .then(data => {
        resolve({
          list: data.data || [],
          total: data.page.totalElements
        })
      })
      .catch(error => {
        resolve({
          list: [],
          total: 0
        })
      });
  })
}
const { tableProps: {
  list,
  paginationProps
} } = useTable(serveApi, {
  refreshDeps: [tab.t, match.params.k]
})
//------------------ 请求 end ------------------
  const classes = tabStyles();

  return (<Box
    className={classes.root}
  >
    <Box
      component={Container}
      className={classes.container}
    >
      {/* 顶部广告条 */}
      <Ad />
      {/* 数据table */}
      <Box>
        <Tabs
          value={value}
          onChange={handleChange}
          style={{
            backgroundColor: '#fff'
          }}
        >
          {tabs.map((tab, index) =>
            <Tab
              key={index}
              label={tab.name}
              className={classes.tab}
            />)}
        </Tabs>
      </Box>
      <DataTable
        classes={classes}
        tab={tab}
        list={list}
        paginationProps={paginationProps}
      />
    </Box>
  </Box >)
}

// tab页签
const tabs = [
  {
    name: '物资招标公告',
    t: '1',
  },
  {
    name: '劳务招标公告',
    t: '3',
  },
  {
    name: '专业分包公告',
    t: '4',
  },
  {
    name: '机械设备公告',
    t: '5',
  },
  {
    name: '其它服务公告',
    t: '7',
  },
]

const tabStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#f5f5f5',
  },
  container: {
    flexGrow: 1,
    paddingBottom: 50,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  tab: {
    fontSize: 16,
  },
  title: {
    backgroundColor: '#ddd',
    fontSize: 16,
    color: 'black',
  },
}))

// 数据表格
const DataTable = React.forwardRef((props,ref) => {
  const { classes,list, paginationProps} = props;
  // 总条数

  

  // 下方action中显示文字方法
  const handleLabelDisplayedRows = (from, to, count) => {
  }



  return <Paper
    square={true}
    elevation={0}
  >
    <Box
      className={classes.tableWrapper}
    >
      <Table
        className={classes.table}
      >
        <TableHead>
          <TableRow>
            <TableCell
              className={classes.title}
            >状态</TableCell>
            <TableCell
              className={classes.title}
              align='center'
            >项目名称</TableCell>
            <TableCell
              className={classes.title}
              align='center'
            >发布时间</TableCell>
            <TableCell
              className={classes.title}
              align='center'
            >截止时间</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map((item, index) => {
            // 判断是否属于1.0的数据
            return <TableRow
              key={index}
            >
              <TableCell
                component='th'
                scope='row'
              >
                <Typography
                  variant='button'
                  noWrap={true}
                  style={{
                    backgroundColor: moment(new Date()).valueOf() < moment(item && item.docnoticemod && item.docnoticemod.deadline).valueOf() ? '#ff8a22' : '#ddd',
                    color: '#fff',
                    padding: 5,
                    boxSizing: 'border-box',
                    borderRadius: 3,
                    fontSize: 12,
                  }}
                >
                  {moment(new Date()).valueOf() < moment(item && item.docnoticemod && item.docnoticemod.deadline).valueOf() ? '进行中' : '已截止'}
                </Typography>
                <Typography
                  variant='button'
                  noWrap={true}
                  style={{
                    backgroundColor: '#ddd',
                    color: '#fff',
                    padding: 5,
                    boxSizing: 'border-box',
                    borderRadius: 3,
                    fontSize: 12,
                    marginLeft: 5,
                  }}
                >
                  {item.tenantName}
                </Typography>
              </TableCell>
              <TableCell
                align='left'
                style={{
                  maxWidth: 300,
                }}
              >
                <ListItem
                  button
                  component={ Link}
                  to={JumpLink({
                    tabType: '招标公告',
                    id: item.id,
                    identity: item.identity,
                    type: item.type,
                  })}
                >
                  <Typography
                    variant='inherit'
                    noWrap={true}
                  >
                    {item.planName}
                  </Typography>
                </ListItem>
              </TableCell>
              <TableCell
                align='center'
              >
                <Typography
                  variant='inherit'
                  noWrap={true}
                  style={{
                    color: '#999',
                  }}
                >  {item && item.publishTime}
                </Typography>
              </TableCell>
              <TableCell
                align='center'
              >
                <Typography
                  variant='inherit'
                  noWrap={true}
                  style={{
                    color: '#999',
                  }}
                >
                  {item && item.deadline}
                </Typography>
              </TableCell>
            </TableRow>
          })}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10]}
              colSpan={3}
              rowsPerPage={10}
              labelDisplayedRows={handleLabelDisplayedRows}
              labelRowsPerPage={''}
              {
                ...paginationProps
              }
              ActionsComponent={TablePaginationActions}
            >
            </TablePagination>
          </TableRow>
        </TableFooter>
      </Table>
    </Box>
  </Paper>
})
const activeStyle = makeStyles(theme => ({
  root: {
    flexShrink: 0,
  }
}));

// 自定义分页按钮
const TablePaginationActions = props => {
  const classes = activeStyle();

  // 首页按钮
  const handleFirstPageButtonClick = event => {
    onChangePage(event, 0);
  }

  // 上一页
  const handleBackButtonClick = event => {
    onChangePage(event, page - 1);
  }

  // 下一页
  const handleNextButtonClick = event => {
    onChangePage(event, page + 1);
  }

  // 最后一页
  const handleLastPageButtonClick = event => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  }

  const { count, page, rowsPerPage, onChangePage } = props;

  return <Box
    className={classes.root}
  >
    <IconButton
      onClick={handleFirstPageButtonClick}
      disabled={page === 0}
      aria-label='首页'
    >
      <FirstPageIcon />
    </IconButton>
    <IconButton
      onClick={handleBackButtonClick}
      disabled={page === 0}
      aria-label='上一页'
    >
      <KeyboardArrowLeft />
    </IconButton>
    <IconButton
      onClick={handleNextButtonClick}
      disabled={page >= Math.ceil(count / rowsPerPage) - 1}
      aria-label='下一页'
    >
      <KeyboardArrowRight />
    </IconButton>
    <IconButton
      onClick={handleLastPageButtonClick}
      disabled={page >= Math.ceil(count / rowsPerPage) - 1}
      aria-label='末页'
    >
      <LastPageIcon />
    </IconButton>
  </Box>
}

export default TenderNotice;