import React, { useEffect } from 'react'
import Home from './components/home/home'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux';
import './App.css'
import 'typeface-roboto'
import './assets/css/googleapis.css'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import SuccessfulBidding from './components/successful-bidding/successful-bidding'
import TenderNotice from './components/tender-notice/tender-notice'
import Header from './components/common/header'
import BulletinDetail from "./components/bulletin/bulletin-detail"
import BiddingDetail from "./components/bidding/bidding-detail"
import Nav from "./components/home/nav"
import Footer from './components/common/footer';
import { mapStateToProps, mapDispatchToProps } from './root.redux.js';
import Axios from 'axios';
import TenderDetails from './components/tender-details'

const App = (props) => {

  useEffect(() => {
    //请求一下各种图片
    Axios.get('/shared-base-data/basic/v1/support/portal-image').then(res => {
      const { data } = res;
      const resultObj = {};
      let _arr = Array.isArray(data) ? data : []
      _arr.forEach(el => {
        resultObj[el.type] = {
          url: el.imgUrl,
          externalLinkUrl: el.externalLinkUrl,
        };
      })
      props.updateUrl(resultObj);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BrowserRouter>
      {/* header */}
      <Route component={Header} />
      {/* 导航条 */}
      <Route component={Nav} />
      {/* 首页 */}
      <Route exact path='/' component={Home} />
      {/* 招标公告 */}
      <Switch>
        <Route path='/tender/notice/:t/:k' component={TenderNotice} />
        <Route path='/tender/notice/:t' component={TenderNotice} />
      </Switch>
      {/* 中标公示 */}
      <Switch>
        <Route path='/tender/result/:t/:k' component={SuccessfulBidding} />
        <Route path='/tender/result/:t' component={SuccessfulBidding} />
      </Switch>
      {/* 招标公告详情 */}
      <Route path={'/bulletin/detail/:id/:identity/:type'} component={BulletinDetail} />
      {/* 供方跳招标公告详情查看 -- 兼容 */}
      <Route path={'/bulletin/detail/:id/:identity'} component={TenderDetails} />
      {/* 中标公示详情 */}
      <Route path={'/bidding/detail/:id/:identity/:type'} component={BiddingDetail} />
      {/* footer */}
      <Footer />
    </BrowserRouter>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
