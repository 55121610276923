import React from 'react'

const ControlImg = (props) => {
    const {data,style} = props;
    return ( 
        <>
            {data.externalLinkUrl ? (
                <a target="_blank" rel="noopener noreferrer" href={data.externalLinkUrl}>
                    <img
                        src={data.url}
                        style={style}
                        alt=''
                    />
                </a>              
            ):(
                <img
                    src={data.url}
                    style={style}
                    alt=''
                />
            )}
        </>
     );
}
 
export default ControlImg;