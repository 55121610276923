import React from 'react'
import { Grid, Box } from '@material-ui/core'
import './searchBox.css'

// 搜索框
class SearchBox extends React.Component {

  constructor(props) {
    super(props);
    this.types = props.types;
    this.state = {
      searchType: this.types[0],
      query: '',
    }
  }

  // 搜索类型切换事件
  handleSearchType = (event) => {
    const index = parseInt(event.target.getAttribute('value'))
    this.setState({
      searchType: this.types[index]
    })
  }

  // 搜索框输入事件
  handleChangeQuery = (event) => {
    this.setState({
      query: event.target.value
    })
  }

  render() {
    const { searchType, query } = this.state;
    const { search } = this.props;
    return <Grid container>
      <Grid item xs={10}>
        <Grid container>
          <Box>
            <Box className='f12' height={24}>
              {/* 搜索框tab */}
              {this.types.map((item, index) => {
                return <span
                  key={index}
                  value={index}
                  className={'search ' + (searchType.index === index && 'active')}
                  onClick={this.handleSearchType}
                >
                  {item.text}
                </span>
              })}
            </Box>
          </Box>
          <Grid
            component={Grid}
            container
            spacing={0}
            className='search-box'
          >
            <Grid item xs={10}>
              {/* 搜索框输入input */}
              <input
                type='text'
                value={query}
                onChange={this.handleChangeQuery}
                placeholder={'请输入' + searchType.text + '关键字'}
              />
            </Grid>
            <Grid item xs={2} style={{ padding: 0 }}>
              {/* 搜索按钮 */}
              <button
                onClick={(e) => search(searchType, query)}
              >搜索
              </button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  }
}

export default SearchBox;