/** 招标公示-typeMap */
export const tenderAnnouncementTypesMap = {
  1:'物资',
  2:'物资',
  3:'劳务分包',
  4:'专业分包',
  5:'机械设备',
  7:'其它服务',
}
/** 中标公示-typeMap */
export const winPublicity = {
  1: '物资',
  2: '物资',
  3: '劳务分包',
  4: '专业分包',
  5: '机械设备',
  7: '其它服务',
}

