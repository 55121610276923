import React from 'react'
import { Breadcrumbs, Typography, Link, Box, makeStyles, Container, Grid, Paper, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core'
import { Link as L } from 'react-router-dom'
import AdList from '../common/ad-list'
import Request from '../../utils/request'
import url from '../../utils/url'
import { winPublicity } from '../../utils/constants'
// 中标公示详情
export default class BiddingDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.match.params.id,
      identity: props.match.params.identity,
      type: props.match.params.type,
      detail: {},
    }
  }

  // 初始化
  componentDidMount() {
    const { id, } = this.state;
    Request.get(`${url.publicityDetails}/${id}`)
      .then(data => {
        this.setState({
          detail: data || {},
        })
      })
      .catch(error => {
        console.warn(error);
      })
  }

  render() {
    const { detail, type } = this.state;
    return <Box>
      {Object.getOwnPropertyNames(detail).length !== 0 &&
        <Content detail={detail} type={type} />
      }
    </Box>
  }
}

// 样式
const styles = makeStyles(theme => ({
  root: {
    backgroundColor: '#f5f5f5',
  },
  bread: {
    color: '#404040',
    fontSize: 12,
    paddingBottom: 20,
  },
  content: {
    padding: 30,
    fontSize: 14,
    lineHeight: 3,
    color: '#666',
  },
  title: {
    fontSize: 24,
    fontWeight: '400',
    color: '#333',
  },
  secondary: {
    fontSize: 12,
    color: '#a5a5a5',
    marginBottom: 30,
  },
  red: {
    color: 'red',
  },
  tableroot: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowx: 'auto',
  },
  table: {
    minWidth: 650,
  },
}));

// 内容
const Content = props => {
  const classes = styles();
  const { detail, type } = props;
  const { name } = detail

  return <Box
    className={classes.root}
  >
    <Box
      component={Container}
    >
      {/* 面包靴导航 */}
      <Breads
        classes={classes.bread}
        type={type}
        name={name}
      />
      <Grid
        container
        spacing={2}
        style={{
          paddingBottom: 40,
        }}
      >
        <Grid
          item
          xs={9}
        >
          <Paper
            square={true}
            elevation={0}
            className={classes.content}
          >
            {/* 中标公示详情 */}
            <Body
              classes={classes}
              detail={detail}
            />
          </Paper>
        </Grid>
        <Grid
          item
          xs={3}
        >
          {/* 右侧广告列表 */}
          <AdList />
        </Grid>
      </Grid>
    </Box>
  </Box>
}

// 中标公示详情
const Body = props => {
  const { classes, detail } = props;
  const { contact, phone, email, name, userName, code, issueTime, viewCount, publishTime, deadline, content } = detail;

  return <Box>
    <Typography
      variant='inherit'
      className={classes.title}
    >
      {name} 中标公示
    </Typography>
    <Grid
      container
      className={classes.secondary}
    >
      <Grid
        item
        xs={2}
      >
        <Typography
          variant='inherit'
        >
          发布人：{userName}
        </Typography>
      </Grid>
      <Grid
        item
        xs={2}
      >
        {issueTime}
      </Grid>
      <Grid
        item
        xs={8}
      >
        <Typography
          variant='inherit'
          align='right'
          component='div'
        >
          点击次数：{viewCount}
        </Typography>
      </Grid>
    </Grid>
    <Typography
      variant='inherit'
      className={classes.red}
      component='p'
    >
      说明：投标人或者其他利害关系人有任何异议，请在公示期内向我司招标中心提出，超出公示期将不予受理。
    </Typography>
    <Typography
      variant='inherit'
      component='p'
    >
      项目编号：{code}
    </Typography>
    <Typography
      variant='inherit'
      component='p'
    >
      招 标 人：{userName}
    </Typography>
    <Typography
      variant='inherit'
      component='p'
    >
      项目名称：{name}
    </Typography>
    <Typography
      variant='inherit'
      component='p'
    >
      公示内容：<span dangerouslySetInnerHTML={{ __html: content }}></span>
    </Typography>
    <Typography
      variant='inherit'
      component='p'
    >
      公示期：{publishTime} ~ {deadline}
    </Typography>
    <Typography
      variant='inherit'
      component='p'
    >
      联系人：{contact}
    </Typography>
    <Typography
      variant='inherit'
      component='p'
    >
      联系电话：{phone}
    </Typography>
    <Typography
      variant='inherit'
      component='p'
    >
      电子邮箱：{email}
    </Typography>
    <Typography
      variant='inherit'
      component='p'
      style={{
        marginTop: 30,
        marginBottom: -20,
      }}
    >
      中标候选单位：
    </Typography>
    {/* 详情表格 */}
    <DetailTable
      classes={classes}
      detail={detail.candidates}
    />
    {/* 历史中标记录 */}
    {detail.changes && detail.changes.length > 0 ? (
      detail.changes.map(el => {
        return (
          <>
            <Typography
              variant='inherit'
              component='p'
              style={{
                marginTop: 30,
                marginBottom: -20,
              }}
            >
              历史中标候选单位：
            </Typography>
            <DetailTable
              classes={classes}
              detail={el.candidates}
            />
          </>
        )
      })
    ) : null}
  </Box>
}

// 详情表格
const DetailTable = props => {
  const { classes, detail } = props;
  return <Paper className={classes.tableroot}>
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell>标包名称</TableCell>
          <TableCell align='center'>中标单位名称</TableCell>
          <TableCell align='center'>中标候选人名次</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {detail && detail.map((item, index) =>
          <TableRow
            key={index}
          >
            <TableCell
              component='th'
              scope='row'
            >
              {item.packageName}
            </TableCell>
            <TableCell
              align='center'
            >
              {item.supplierName}
            </TableCell>
            <TableCell
              align='center'
            >
              第<span style={{ color: 'red' }} >{item.ranking}</span>中标候选人
            </TableCell>
          </TableRow>)}
      </TableBody>
    </Table>
  </Paper>
}

// 导航
const Breads = props => {

  const { classes, type, name } = props;
  console.log(type, 'type');
  const _type = `${type}` === '2' ? 1 : type
  return <Box
    marginBottom={3}
    paddingTop={2}
  >
    <Breadcrumbs
      className={classes}
      aria-label="breadcrumb"
    >
      <Link
        color="inherit"
        component={L}
        to={'/tender/result/1'}
      >
        中标公示
      </Link>
      <Link
        color="inherit"
        component={L}
        to={`/tender/result/${_type}`}
      >
        {winPublicity[_type] + '中标公示' || ''}
      </Link>
      <Typography
        variant='inherit'
        color="textPrimary"
      >
        {name || ''}
      </Typography>
    </Breadcrumbs>
  </Box>
}