import React from 'react'
import { Box, Card, CardContent, Typography, Grid, CardActionArea, Button } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

// 云彩指数详情
export default function Exponent() {
  const classes = cardStyles();
  return <Box>
    <Box
      paddingX={3}
      paddingTop={1}
      minHeight={440 - 46}
    >
      <Grid
        container
        spacing={0}
      >
        {data.map((item, index) =>
          <Grid
            item
            key={index}
            xs={6}
          >
            <Content
              classes={classes}
              item={item}
            />
          </Grid>
        )}
        <Grid
          item
          xs={12}
        >
        </Grid>
      </Grid>
    </Box>
    <Box
      height={46}
      className={classes.more}
    >
      <span className={classes.onlineLicensing}>建筑行业大宗材料价格指数权威在线发布</span>
      <Button
        component={Link}
        className={classes.button}
        variant='contained'
        to=''
      >
        了解更多
      </Button>
    </Box>
  </Box>
}

// 云彩指数数据
const data = [
  {
    name: '圆钢',
    type: 'HPB300 φ12',
    price: 4840,
    change: -40,
    footer: '长峰/成都 12/08 13:08',
  },
  {
    name: '三级螺纹钢',
    type: 'HRB400E φ10',
    price: 4740,
    change: -40,
    footer: '亚新/成都 12/08 13:08',
  },
  {
    name: '圆钢',
    type: 'HPB300 φ12',
    price: 4790,
    change: -40,
    footer: '成实/成都 12/08 13:08',
  },
  {
    name: '圆钢',
    type: 'HPB300 φ12',
    price: 4830,
    change: -40,
    footer: '达钢/成都 12/08 13:08',
  },
]

// 样式
const cardStyles = makeStyles({
  card: {
  },
  main: {
    fontSize: 16,
    fontWeight: 'bold',
    "@media (max-device-width: 400px)": {
      width:'100%',
      display:'inline-block'
    },
    "@media (max-width: 1200px)": {
      width:'100%',
      display:'inline-block'
    }
  },
  secondary: {
    fontSize: 12,
    color: '#999',
    "@media (max-device-width: 400px)": {
      width:'100%',
      display:'inline-block'
    },
    "@media (max-width: 1200px)": {
      width:'100%',
      display:'inline-block'
    }
  },
  description: {
    fontSize: 14,
    color: '#999',
    marginTop: 25,
    marginBottom: 25,
  },
  price: {
    color: '#11c762',
    fontSize: 22,
  },
  change: {
    color: '#11c762',
    fontSize: 18,
    marginLeft: 20,
  },
  footer: {
    fontSize: 12,
    marginTop: 20,
  },
  more: {
    backgroundColor: '#EC4B43',
    color: '#fff',
    fontSize: 16,
    paddingLeft: '3%',
    lineHeight: 2.7,
  },
  button: {
    color: '#EC4B43',
    backgroundColor: '#fff',
    marginLeft: '3%',
  },
  onlineLicensing:{
    "@media (max-width: 1100px)": {
      display:'inline-block',
      lineHeight:'16px',
      width:'66%',
      overflow:'hidden',
      textOverflow:'ellipsis',
      whiteSpace:"nowrap"
    }
  }
})

// 内容
const Content = props => {
  const { classes, item } = props;
  return <Card
    className={classes.card}
    square={true}
  >
    <CardActionArea>
      <CardContent>
        <Box>
          <Typography
            className={classes.main}
            color='textPrimary'
            variant='inherit'
            gutterBottom
          >
            {item.name}
          </Typography>
          <Typography
            className={classes.secondary}
            color="textSecondary"
            variant='inherit'
            gutterBottom
          >
            {item.type}
          </Typography>
        </Box>
        <Typography
          variant="h5"
          component="h2"
          className={classes.description}
          gutterBottom
        >
          指导价（含税） 涨跌情况
      </Typography>
        <Typography
          variant='h5'
          component="span"
          className={classes.price}
          gutterBottom
        >
          {item.price}
          <ExpandMoreIcon />
        </Typography>
        <Typography
          variant='h5'
          component="span"
          className={classes.change}
          gutterBottom
        >
          {item.change}
        </Typography>
        <Typography
          variant="body2"
          component="p"
          className={classes.footer}
        >
          {item.footer}
        </Typography>
      </CardContent>
    </CardActionArea>
  </Card>
}