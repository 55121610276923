import React from 'react'
import { 
  Box, makeStyles, Container, Grid, List, 
  ListItem, ListItemText, Typography, Button, Link 
} from '@material-ui/core'
import { connect } from 'react-redux';
import {mapStateToProps,mapDispatchToProps} from '../../root.redux.js';
// import Logo from '../../assets/images/f_logo.png'
import ControlImg from './control-img';
import Safe from '../../assets/images/360.gif'

// footer
const Footer = props => {
  
  const whetherscjzy = (window.location.host.indexOf('scjzy.net') > -1);
  const {BOTTOM_LOGO} = props
  const classes = footerStyles();
  return <Box
    className={classes.root}
  >
    <Container>
      <Grid
        container
        spacing={3}
        className={classes.top}
      >
        <Grid
          item
          xs={9}
        >
          <Grid
            container
            spacing={1}
          >
            {list_data.map((item, index) =>
              <Grid
                item
                key={index}
                xs={12 / list_data.length}
              >
                <List
                  component='nav'
                >
                  <ListItem>
                    <ListItemText
                      className={classes.title}
                      primary={item.title}
                    />
                  </ListItem>
                  {item.list && item.list.map((link, index) =>
                    <ListItem
                      button
                      key={index}
                      component='a'
                      href={link.url}
                      target='_blank'
                    >
                      <Typography
                        className={classes.secondary}
                      >
                        {link.text}
                      </Typography>
                    </ListItem>)}
                </List>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid
          item
          xs={3}
        >
          <Box
            className={classes.right}
          >
            <ControlImg style={{maxHeight:'320px',width:'60%'}} data={BOTTOM_LOGO} />
            <Typography
              align='center'
              className={classes.phone}
            >
              — 400-000-2772 —
            </Typography>
            <Typography
              className={classes.time}
            >
              周一至周五 8:30-17:30
            </Typography>
            <Button
              variant='outlined'
              className={classes.button}
            >
              联系在线客服
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Box
        className={classes.below}
      >
        <Box>
          <Typography
            variant='inherit'
          >
            友情链接：
        </Typography>
          {links.map((link, index) =>
            <Link
              key={index}
              href={link.url}
              color='inherit'
              className={classes.link}
              target='_blank'
              rel='noreferrer'
            >
              {link.text}
            </Link>
          )}
        </Box>
        <Box>
          {links2.map((link, index) =>
            <Link
              key={index}
              href={link.url}
              color='inherit'
              className={classes.link}
              target='_blank'
              rel='noreferrer'
            >
              {link.text}
              {index === links2.length - 1 ?
                false : <Typography
                  variant='inherit'
                  style={{
                    marginLeft: 10,
                    marginRight: -10,
                  }}
                >|</Typography>}
            </Link>)}
        </Box>
        <Typography
          variant='inherit'
        >
          四川华西集采电子商务有限公司版权所有
          <Link
            href='https://beian.miit.gov.cn/'
            color='inherit'
            target='_blank'
            rel='noreferrer'
            style={{
              marginLeft: 10,
              marginRight: 10,
            }}
          >
            蜀ICP备16033894号-{whetherscjzy ? 5 : 2}
          </Link>
          增值电信业务经营许可证：川B2-20170149 Copyright©2017 www.hxyc.com.cn All Rights Reserved
        </Typography>
        <Box>
          <img
            src={Safe}
            alt='安全认证'
          />
        </Box>
      </Box>
    </Container>
  </Box>
}

const footerStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#4d4d4d',
    color: '#fff',
    paddingTop: 40,
    paddingBottom: 20,
  },
  title: {
    fontSize: 14,
  },
  secondary: {
    fontSize: 12,
    color: '#999',
  },
  right: {
    borderLeft: '1px solid',
    textAlign: 'center',
    paddingTop: 20,
  },
  phone: {
    fontWeight: 'bold',
    fontSize: 18,
    marginTop: 5,
  },
  time: {
    fontSize: 12,
    marginTop: 5,
    color: '#999',
  },
  button: {
    color: '#fff',
    borderColor: '#fff',
    marginTop: 20,
    paddingLeft: 30,
    paddingRight: 30,
  },
  top: {
    paddingBottom: 30,
    borderBottom: '1px solid #3b3b3b',
  },
  below: {
    borderTop: '1px solid #636363',
    paddingTop: 30,
    color: '#ccc',
    fontSize: 12,
    textAlign: 'center',
    lineHeight: 2.5,
  },
  link: {
    marginLeft: 20,
  },
}));

const links = [
  {
    text: '华西集团',
    url: 'http://www.huashi.sc.cn',
  },
  {
    text: '华西工会',
    url: 'http://www.huashigh.cn/',
  },
  {
    text: '华西云采',
    url: 'https://www.hxyc.com.cn/',
  },
  {
    text: '我的钢铁网',
    url: 'http://www.mysteel.com/',
  },
  {
    text: '绵阳市建筑施工企业协会',
    url: 'http://www.myjsxh.org/',
  },
]

const links2 = [
  {
    text: '关于我们',
    url: '',
  },
  {
    text: '联系我们',
    url: '',
  },
  {
    text: '供应商入驻',
    url: '',
  },
  {
    text: '商务合作',
    url: '',
  },
  {
    text: '帮助中心',
    url: 'http://help.scjzy.net/',
  },
  {
    text: '服务条款',
    url: '',
  },
  {
    text: '法律声明',
    url: '',
  },
  {
    text: '网站地图',
    url: '',
  },
]

const list_data = [
  {
    title: '关于我们',
    list: [
      {
        text: '用户协议',
        url: '',
      },
      {
        text: '供应商入驻标准',
        url: '',
      },
      {
        text: '联系我们',
        url: '',
      },
      {
        text: '法律声明',
        url: '',
      },
    ],
  },
  {
    title: '帮助中心',
    list: [
      {
        text: '视频中心',
        url: 'http://college.hxyc.com.cn/videolist.aspx',
      },
      {
        text: '操作手册',
        url: 'http://college.hxyc.com.cn/newslist.aspx?cid=6',
      },
      {
        text: '常见问题',
        url: 'https://mp.weixin.qq.com/mp/homepage?__biz=MzI3NjUxNTczMg==&hid=2&sn=2aeeb75aad54a0e5c23983f7e7ba5a10#wechat_redirect',
      },
    ],
  },
  {
    title: '商务合作',
    list: [
      {
        text: '入驻合作协议',
        url: '',
      },
      {
        text: '营销推广',
        url: '',
      },
      {
        text: '广告宣传',
        url: '',
      },
    ],
  },
  {
    title: '我是供应商',
    list: [
      {
        text: '商家准入机制',
        url: '',
      },
      {
        text: '商家投标',
        url: '',
      },
      {
        text: '商家评价体系',
        url: '',
      },
    ],
  },
  {
    title: '我是需求单位',
    list: [
      {
        text: '需求方招标',
        url: '',
      },
      {
        text: '需求方验收',
        url: '',
      },
      {
        text: '需求方结算',
        url: '',
      },
    ],
  },
  {
    title: '交易保障',
    list: [
      {
        text: '交易安全保障',
        url: '',
      },
      {
        text: '争议仲裁',
        url: '',
      },
      {
        text: '供应商防骗',
        url: '',
      },
      {
        text: '需求单位防骗',
        url: '',
      },
    ],
  },
]

export default connect(mapStateToProps,mapDispatchToProps)(Footer);