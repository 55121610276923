import React from 'react'
import { Paper, Box, Container, Grid } from '@material-ui/core'
import PlanTitle from './plan-title'
import BiddingBody from './bidding-body'
import Exponent from './exponent'

// 中标公示
const Bidding = props =>
  <Box
    paddingY={5}
    component={Container}
  >
    <Grid
      container
      spacing={3}
    >
      {/* 中标公示 */}
      <Grid
        item
        xs={7}
      >
        <Paper
          square={true}
          elevation={0}
          style={{
            paddingTop: 12,
            paddingBottom: 12,
          }}
        >
          {/* 中标公示标题 */}
          <PlanTitle
            title='中标公示'
            to='/tender/result/1'
            more={true}
          />
          {/* 中标公示列表 */}
          <BiddingBody />
        </Paper>
      </Grid>
      {/* 云采指数 */}
      <Grid
        item
        xs={5}
      >
        <Paper
          square={true}
          elevation={0}
          style={{
            paddingTop: 12,
          }}
        >
          {/* 云采指数标题 */}
          <PlanTitle
            title='云采指数'
            to=''
          />
          {/* 云采指数内容 */}
          <Exponent />
        </Paper>
      </Grid>
    </Grid>
  </Box>

export default Bidding;